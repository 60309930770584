import ContactUs from "../../components/ContactUs.js";
import "../../css/HelpSection.css";
import { HeadProvider, Title, Meta } from "react-head";
import { Link } from "react-router-dom";
import tinyLogo from "../../img/abraTinyLogo.png";
import { useEffect, useState } from "react";
import { getPrice } from "../../components/api/getPrice.js";

export default function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  const [price, setPrice] = useState("");

  useEffect(() =>{
    getPrice().then((result) => setPrice(result))
  } )
  return (
    <HeadProvider>
      <Title>Abra - FAQ</Title>
      <Meta
        name="description"
        content="Lurer du på noe angående Abra Dentals tjenester? Les våre FAQ"
      />

      <div class="helpSectionPage">
        <h1 class="sectionHeadder">Vanlige spørsmål</h1>
        <h2>Hva er Abra</h2>
        <ul>
          <li>
            Abra Dental er virtuell tannklinikk med mål om å gjøre tannlegen mer
            tilgjengelig, og at alle skal ha et tilbud de faktisk ønsker og kan
            benytte uavhengig av økonomi, hvor man bor eller hva man jobber med.
            Alt vi gjør er med mål om at dine tenner skal være så sunne og
            sterke som mulig.{" "}
            <Link class="readMoreLink" to="/omoss">
              Les mer om oss her.
            </Link>
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Hvordan fungerer tjenesten</h2>
        <ul>
          <li>
            Vi tilbyr videokonsultasjon med tannlege via nettleser på din PC,
            mobil eller nettbrett.{" "}
            <Link class="readMoreLink" to="/#howItWorks">
              Les mer om dette her.
            </Link>
          </li>
          <li>
            Tannlegen vil snakke med deg, og eventuelt be om å få se inn i
            munnen din for å gjøre en visuell vurdering av din situasjon. Basert
            på dette vil du få informasjon og råd om hva du bør gjøre videre.
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Hva kan en tannlege hjelpe meg med på video?</h2>
        <ul>
          <li>
            Typiske henvendelser gjelder akutte tannskader, spørsmål om tenner
            hos barn, tannlegeskrekk, visdomstenner, tannbleking, ising/tannpine
            og regulering.{" "}
            <Link class="readMoreLink" to="/tjenester">
              Les mer om dette her.
            </Link>
          </li>

          <li>
            Våre tannleger får daglig telefoner i sin praksis med diverse
            spørsmål og er vant til å hjelpe pasienter uten fysisk undersøkelse.
            Ved å benytte video kan vi tilby deg tilgang til tannlege uavhengig
            av tid på døgnet og hvor du er i verden.
          </li>
          <li>
            Det er naturligvis noe begrenset hva slags undersøkelser og
            behandling en tannlege kan utføre digitalt, men i de fleste
            tilfeller vil vi få god nok oversikt over situasjonen til å kunne gi
            gode råd og veiledning, og igangsette behandling med smertestillende
            eller antibiotika i noen tilfeller der det er nødvendig.
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Hva kan dere ikke hjelpe meg med?</h2>
        <ul>
          <li>
            o Siden vi tilbyr virtuelle konsultasjoner kan vi ikke hjelpe deg
            med fysisk behandling, som f.eks. bore hull eller reparere skadde
            tenner. Vi skriver som regel ikke ut smertestillende av typen
            Paralgin forte eller andre såkalte <Link to="https://www.lommelegen.no/medisiner/artikkel/a--b--og-c-preparater/69096496">B-preparater.</Link>
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Er det trygt og sikkert å bruke Abra</h2>
        <ul>
          <li>
            Ja. Våre systemer er utviklet i henhold til nasjonale normer og
            regler for informasjonssikkerhet. Ved å bruke kryptere din
            informasjon sikrer vi at ingen andre deg får tilgang til din
            informasjon. Vi fører alltid journal i <Link to="https://www.opusdental.com/no/">Opus Dental</Link>, som er trygt og
            sikkert og brukes av de aller fleste tannleger i landet.
          </li>
          <li>
            Våre tannleger er blant Norges dyktigste og mest erfarne. Du kan
            føle deg helt trygg på at de kun vil det beste for deg og dine
            tenner, og vil gi deg gode råd og veiledning. <Link to="/ansatte/oversikt">Les mer om våre
            tannleger her.</Link>
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Kan jeg booke time på vegne av noen andre, f.eks. mine barn?</h2>
        <ul>
          <li>
            Ja. Når du booker time, fyller du informasjon for den personen det
            gjelder. Når du skal inn i videorommet, trykker du på lenken du har
            mottatt som e-post.
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Hva er en second opion?</h2>
        <ul>
          <li>
            Noen opplever at det tannlegen anbefaler av behandling og priser er
            overdrevet eller unødvendig. Da kan det være fint å diskutere med en
            annen, uavhengig tannlege. Dette er noe du kan få hjelp til hos
            Abra. For en best mulig second opinion, anbefaler vi at du får
            utskrift av din journal fra siste besøk hos din tannlege. Som eier
            egen journal har du krav på dette etter norsk lov.
          </li>
        </ul>

        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>

        <h2>Sender tannlegen rapport til min tannlege?</h2>
        <ul>
          <li>
            Ved behov for fysisk behandling vil våre tannleger gi deg nøytral
            informasjon og veiledning om hvilke tilbud som er tilgjengelig, og
            hva som er nødvendig behandling ut ifra hva vi kan vurdere uten selv
            å gjøre en fysisk undersøkelse. På den måten stiller du godt
            informert i møte med tannlegestolen hos en fysisk tannlege.{" "}
          </li>
        </ul>

        <img class="tinyLogo" alt="decorative tooth" src={tinyLogo}></img>

        <h2>Jeg er ikke fornøyd og vil ha tilbake pengene. Hva gjør jeg?</h2>
        <ul class="lastFAQ">
          <li>
            Vi beklager på det sterkeste at du ikke er fornøyd. Ta kontakt via
            <Link to="/kontakt">vårt kontaktskjema</Link> og forklar situasjonen, så kommer vi tilbake til
            deg så fort som mulig og innen 24 timer.{" "}
          </li>
        </ul>

        {/* <img class="tinyLogo lastFAQ" alt="decorative tooth" src={tinyLogo}></img> */}
        <img class="tinyLogo" alt="fillerImg" src={tinyLogo}></img>


        <h2 class="seperateHeadder">Betaling</h2>

        <h3>Hvor mye koster en videokonsultasjon med Abra?</h3>
        <ul class="faqQuickfire">
          <li>Konsultasjon med våre tannleger koster {price} kr for 15 minutter</li>
        </ul>{" "}


        <h3>Hvordan betaler jeg?</h3>
        <ul class="faqQuickfire">
          <li>Du betaler ved bruk av kort når du booker timen. <Link to="/#howItWorks">Les mer her.</Link></li>
        </ul>{" "}



        <h3>Kan jeg betale med Vipps?</h3>
        <ul class="faqQuickfire">
          <li>Vi tilbyr foreløpig ikke betaling med Vipps.</li>
        </ul>{" "}


        <h3>Kan jeg avbestille konsultasjonen?</h3>
        <ul class="faqQuickfire">
          <li>
            Om du avbestiller konsultasjonen minimum 24 timer i forkant vil du
            få refundert hele beløpet. Avbestillinger mindre enn 24 timer i
            forkant vil bli belastet fullt.
          </li>
        </ul>
        <div class="contactUsSection">
          <ContactUs />
        </div>
      </div>
    </HeadProvider>
  );
}
