import { Link, useNavigate } from "react-router-dom";
import "../../css/AdminPage.css";
import axios from "axios";
import { useEffect, useState } from "react";
import checkUser from "../../components/checkUser.js";
import { getHours } from "../../components/api/getHours.js";

export default function DashboardAdmin() {

  const [price, setPrice] = useState();
  const [startTime, setStartTime] = useState();
  const [startTimeSat, setStartTimeSat] = useState();
  const [endTime, setEndTime] = useState();
  const [endTimeSat, setEndTimeSat] = useState();

  useEffect (() => {
    checkUser("adminDashboard");
    getPrice();
    getStartEndTime();
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function editPrice(e) {
    e.preventDefault();
    let price = document.getElementById("price").value;
    
    if (price) {
      axios.post(`${process.env.REACT_APP_APP_URL}editPrice`, {price}).then((result) => {
        if(result.data.affectedRows === 1) {
          document.getElementById("feedback").innerHTML = "Vellykket endring";
          setInterval(() => {
            window.location.reload();
          },1500)
        }
      })
    }
  }


  async function editStartEndTime(e) {
    e.preventDefault();
    let startTime = document.getElementById("startTime").value;
    let endTime = document.getElementById("endTime").value;
    
    if (startTime && endTime) {
      axios.post(`${process.env.REACT_APP_APP_URL}editStartEndTime`, {startTime, endTime}).then((result) => {
        
        if(result.data.affectedRows === 1) {
          document.getElementById("feedback").innerHTML = "Vellykket endring";
          setInterval(() => {
            window.location.reload();
          },1500)
        }
      })
    }
  }

  async function editStartEndTimeSat(e) {
    e.preventDefault();
    let startTime = document.getElementById("startTimeSat").value;
    let endTime = document.getElementById("endTimeSat").value;
    
    if (startTime && endTime) {
      axios.post(`${process.env.REACT_APP_APP_URL}editStartEndTimeSat`, {startTime, endTime}).then((result) => {
        
        if(result.data.affectedRows === 1) {
          document.getElementById("feedback").innerHTML = "Vellykket endring";
          setInterval(() => {
            window.location.reload();
          },1500)
        }
      })
    }
  }

  async function getPrice() {
    axios.post(`${process.env.REACT_APP_APP_URL}getPrice`).then((result) => {
      setPrice(result.data[0].price)
    })
  }

  async function getStartEndTime() {
    getHours().then((result) => {
      setStartTime(result[0].startTime)
      setEndTime(result[0].endTime)    
      setStartTimeSat(result[1].startTime)
      setEndTimeSat(result[1].endTime)      
    })

  }

  return(
    <div class="dashAdminPage">
      <h1 class="sectionHeadder">Velkommen</h1>

      <div class="dashAdminCont">
        <div><Link class="dashAdminItems" to="/ansatte">Se dine ansatte</Link></div>
        <div><Link class="dashAdminItems" to="/bookings">Se bestillinger</Link></div>
        <div><Link class="dashAdminItems" to="/createArticle">Legg ut artikkel</Link></div>
        <div><Link class="dashAdminItems" to="/viewArticles">Se alle artikler</Link></div>
      </div>

      <form class="dashAdminForm" >
        <label class="formLabel">Endre pris tjeneste</label>
        <input id="price" type="number" class="formInput" placeholder={price}></input>
        <button onClick={(e) => editPrice(e)}>Oppdater</button>
      </form>
    
      <form class="dashAdminForm" >
        <label class="formLabel">Endre start og slutt-tidspunkt mandag-fredag</label>
        <input id="startTime" type="number" class="formInput" placeholder={startTime}></input>
        <input id="endTime" type="number" class="formInput" placeholder={endTime}></input>
        <button onClick={(e) => editStartEndTime(e)}>Oppdater</button>
      </form>

      <form class="dashAdminForm" >
        <label class="formLabel">Endre start og slutt-tidspunkt lørdag-søndag</label>
        <input id="startTimeSat" type="number" class="formInput" placeholder={startTimeSat}></input>
        <input id="endTimeSat" type="number" class="formInput" placeholder={endTimeSat}></input>
        <button onClick={(e) => editStartEndTimeSat(e)}>Oppdater</button>
      </form>
      {/* <div id="feedback"></div> */}
      <div id="feedback"></div>

    </div>
  )
};
