import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Helmet } from "react-head";
import "../css/CookiesBanner.css";

export default function CookiesBanner() {
  const [cookiesAcceptance, setCookiesAcceptance] = useState(false);
  const [cookies, setCookie] = useState();
  const [consentCookie, setConsentCookie] = useCookies("consent");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (!isLoaded) {
      getCookie();
    } else {
      setInterval(() => {
        getCookie();
      }, 600000);
    }
  });

  async function getCookie() {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}cookies`,
        {},
        { withCredentials: true }
      )
      .then((result) => {

        if (result.data.consent === undefined) {
          setIsHidden(false);
        }

        if (!result.data.consent) {
          setIsHidden(false);
        }
        if (result.data.consent) {

          setCookie({ consent: result.data.consent });
          setConsentCookie(result.data.consent);
          setIsLoaded(true);
          const script = document.createElement("script");
          script.src = `https://www.googletagmanager.com/gtag/js?id=G-FHF3H5D7GM`;
          script.async = true;
          document.head.appendChild(script);

          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());

          gtag("config", "G-FHF3H5D7GM");

          (function (c, l, a, r, i, t, y) {
            c[a] =
              c[a] ||
              function () {
                (c[a].q = c[a].q || []).push(arguments);
              };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, "clarity", "script", "i1d44jdz4u");
        }
      });
  }

  function acceptCookies(e) {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_APP_URL}` + "consentCookie",
        { approved: true },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.data === true) {
          document.getElementById("cookieBannerBody").style.display = "none";
          setCookiesAcceptance(true);
        }
      });
  }

  function declineCookies(e) {
    e.preventDefault();
    setCookiesAcceptance(false);

    document.getElementById("cookieBannerBody").style.display = "none";
  }

  if (!isHidden)
    return (
      <div
        id="cookieBannerBody"
        style={cookies ? { display: "none" } : { display: "grid" }}
      >
        <div class="cookieBannerCont">
          <div class="cookieCont">
            {/* <form class="cookieCont"> */}
            <p class="cb-text">
              Abra bruker Google analytics og andre verktøy for å kunne gi deg
              en forbedret opplevelse av siden og tjenesten.
            </p>

            <button class="cb-btn deny" onClick={(e) => declineCookies(e)}>
              Avslå
            </button>
            <button class="cb-btn accept" onClick={(e) => acceptCookies(e)}>
              Godta
            </button>
            {/* </form> */}
          </div>
        </div>
      </div>
    );
  else {
    return <div></div>;
  }
}
