import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as ReactDOM from "react-dom";
import axios from "axios";

class MyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
    };
  }
  content = this.props.content;

  modules = {
    toolbar: [
      [{ header: [false, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      // ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ];

  handleChange = (value) => {
    this.setState({ text: value });
  };

  render() {
    const { content, button } = this.props;

    // if(content.length > 0) {
    return (
      <div className="text-editor">
        <form class="createArticleForm">
          {/* <form onSubmit={onSubmit(data, this.state.text)}> */}
          <label htmlFor="tittel" class="sr-only">
            Tittel
          </label>
          <input id="titleElement" placeholder="Tittel" />
          <label htmlFor="heroImage" class="sr-only">
            Hero bilde
          </label>
          <input
            type="file"
            id="heroElement"
            accept="image/*"
            placeholder="Hero bilde"
          />
          <label htmlFor="metatittel" class="sr-only">
            Meta tittel
          </label>
          <input id="metatitleElement" placeholder="Meta tittel" />
          <label htmlFor="metadesc" class="sr-only">
            Meta description
          </label>
          <input id="metadescElement" placeholder="Meta beskrivelse" />
        </form>

        <div class="textEditorCont">
          <ReactQuill
            theme="snow"
            defaultValue={content}
            modules={this.modules}
            formats={this.formats}
            onChange={this.handleChange}
          ></ReactQuill>
        </div>
        {/* <p>Value: {this.state.text}</p> */}

        <button
          onClick={() => onSubmit(this.state.text)}
          id="createBlogButton"
          class="btnBook"
        >
          Opprett blogg
        </button>
        <div id="feedbackBlogg"></div>
      </div>
    );
    // }
  }
}
async function trackChange(text) {
  let body = document.getElementById("bodyElement").value;
  body += text;
}

async function handleTag(e) {
  let tag = e.target.innerHTML;
  if (tag === "h1") {
    trackChange("<br><h1></h1>");
  } else if (tag === "h2") {
    trackChange("<br><h2></h2>");
  } else if (tag === "h3") {
    trackChange("<br><h3></h3>");
  } else if (tag === "h4") {
    trackChange("<br><h4></h4>");
  } else if (tag === "bold") {
    trackChange("<strong></strong>");
  } else if (tag === "italics") {
    trackChange("<i></i>");
  }
}
async function onSubmit(text) {
  let title = document.getElementById("titleElement").value;
  let heroImage = document.getElementById("heroElement");
  let heroImage2 = heroImage.files[0];

  let metaTitle = document.getElementById("metatitleElement").value;
  let metaDesc = document.getElementById("metadescElement").value;

  const blogg = {
    title: title,
    heroImage: heroImage2,
    metatitle: metaTitle,
    metadesc: metaDesc,
    body: text,
  };

  const formData = new FormData();
  formData.append("image", heroImage2);
  formData.append("title", title);
  formData.append("metatitle", metaTitle);
  formData.append("metadesc", metaDesc);
  formData.append("body", text);

  axios
    .post(`${process.env.REACT_APP_APP_URL}` + "createBlog", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      document.getElementById("feedbackBlogg").innerHTML = result.data;
      setInterval(() => {
        window.location.reload();
      }, 1500);
    });
}

export default MyComponent;
