import ClockIcon from "../img/ClockIcon.png";
import ShieldIcon from "../img/shieldIcon.png";
import CoinsIcon from "../img/CoinsIcon.png";
import CameraIcon from "../img/CameraIcon.png";
import MicIcon from "../img/MicIcon.png";
import InternettIcon from "../img/InternettIcon.png";
import klokke from "../img/IkonerForside/klokke.png";
import person from "../img/IkonerForside/person.png";
import kort from "../img/IkonerForside/kort.png";
import notat from "../img/IkonerForside/notat.png";
import PartnerExuma from "../img/Samarbeidspartnere/PartnerExuma.png";
import MicrosoftStartup from "../img/Samarbeidspartnere/MicrosoftStartup.png";
import Daily from "../img/Samarbeidspartnere/Daily.png";
import AG from "../img/Samarbeidspartnere/AG.png";
import { ReactComponent as BruksanvisningVerticalSvg } from "../img/BruksanvisningVertical-V3.svg";
import { ReactComponent as BruksanvisningVerticalSvg2 } from "../img/BruksanvisningVertical-V2.svg";

import TannlegeReel from "../components/TannlegeReel.js";
import CookiesBanner from "../components/CookiesBanner.js";
import HeroSlideshow from "../components/HeroSlideshow.js";
import ContactUs from "../components/ContactUs.js";
import ArticlesReel from "../components/ArticlesReel.js";
import { getPrice } from "../components/api/getPrice.js";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { HeadProvider, Title, Meta } from "react-head";
import "../css/LandingPage.css";
import "../css/GlobalComponents.css";
import { getHours } from "../components/api/getHours.js";

export default function Landingpage() {
  const [price, setPrice] = useState();
  const [hours, setHours] = useState();

  useEffect(() => {
    getPrice().then((result) => setPrice(result));
  });

  useEffect(() => {
    getHours().then((result) => {
      setHours(result);
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HeadProvider>
      <div class="wrapper">
        <Title>Abra - digital konsultasjon med tannlege</Title>
        <Meta
          name="description"
          content="Med Abra får du raskt og enkelt tilgang til noen av Norges mest erfarne tannleger, når og hvor det passer deg. På video får vi god oversikt over situasjonen og kan gi deg gode råd og veiledning."
        />
        <CookiesBanner />
        <div class="heroSection">
          {/* <HeroSlideshow interval={2000} /> */}
          <HeroSlideshow interval={20000} />
          {/* <div class="heroBooking">
            <div class="heroBookingInner">
              <h1> Snakk med en av våre tannleger om det du lurer på</h1>
              <button>
                <Link class="btnBook" to="/booking" id="BookTimeLink">
                  Book første ledige
                </Link>
              </button>
            </div>
          </div> */}
        </div>

        <div class="whyChooseUsSection">
          <div class="whyUsCol">
            <div class="whyUsColInner">
              <p className="whyUsItem wuP1">Kort ventetid</p>
              <img
                className="whyUsItem wuI1"
                src={ClockIcon}
                alt="decorative Icon"
              ></img>

              <p className="whyUsItem wuP2">Trygt og konfidensielt</p>
              <img
                className="whyUsItem wuI2"
                src={ShieldIcon}
                alt="decorative Icon"
              ></img>

              <p className="whyUsItem wuP3">Kun {price},-</p>
              <img
                className="whyUsItem wuI3"
                src={CoinsIcon}
                alt="decorative Icon"
              ></img>

              <p className="whyUsItem wuP4">Åpningstider</p>
              <p className="whyUsItem wuI4">Mandag-fredag: {hours ? hours[0].startTime : ''} - {hours ? hours[0].endTime : ''}</p> 
              <p className="whyUsItem wuI5">Lørdag-søndag: {hours ? hours[1].startTime : ''} - {hours ? hours[1].endTime : ''}</p>  
            </div>
          </div>

          <div class="connectionCol">
            <p>Enkel tilkobling med mobil, pc eller nettbrett</p>
            <div class="connectIconCont">
              <img src={CameraIcon} alt="decorative Icon"></img>
              <img src={MicIcon} alt="decorative Icon"></img>
              <img src={InternettIcon} alt="decorative Icon"></img>
            </div>
          </div>
        </div>

        <div class="ansatteSection box">
          <h2 class="sectionHeadderPill">
            <Link to="/ansatte/oversikt">Våre tannleger</Link>
          </h2>
          <TannlegeReel />
          <p class="ansatteTekst">
            Team Abra består av noen av Norges mest erfarne tannleger, med tung
            faglig bakgrunn.
          </p>
        </div>
      </div>

      <div class="howItWorksSection">
        <h2 class="sectionHeadder inbetweenSectionHeadder" id="howItWorks">
          Slik fungerer det
        </h2>
        <p class="howItWorksInfo">
          Med Abra får du raskt og enkelt tilgang til noen av Norges mest
          erfarne tannleger, når og hvor det passer deg. På video får vi god
          oversikt over situasjonen og kan gi deg gode råd og veiledning.
        </p>

        <button class="btnBookWhyUs">
          <Link class="btnBook" to="/booking" id="BookTimeLinkSecond">
            Book time
          </Link>
        </button>

        <div class="stepByStepCont">
          {/* <img class="brukIllu illuHorisontal" src={bruksanvisningSvg}></img> */}
          {/* <img
            class="brukIllu illuVertikal"
            src={bruksanvisningVertikalSvg}
            alt="illustration how the process works"
          ></img> */}

          {/* <BruksanvisningSvg
            className="brukIllu illuHorisontal"
            alt="illustration how the process works"
          /> */}
          {/* <div class="svgImg"> */}
          <BruksanvisningVerticalSvg
            className="brukIllu illuVertikal"
            alt="illustration how the process works"
          />
          {/* <BruksanvisningVerticalSvg2
            className="brukIllu illuVertikal"
            alt="illustration how the process works"
          /> */}

          {/* </div> */}

          <div class="step step1">
            <p class="stepNr">1</p>
            <img class="iconMobile" src={klokke}></img>
            <p class="bookSessionInfo">
              Ved å trykke «Book time» får du opp de 5 første ledige timene. Du
              kan velge en av disse, eller trykke på en annen dato for å se hva
              som er ledig.
            </p>
          </div>

          <div class="step step2">
            <p class="stepNr">2</p>
            <img class="iconMobile" src={notat}></img>

            <p class="contactInfo">Fyll inn kontaktinformasjon.</p>
          </div>

          <div class="step step3">
            <p class="stepNr">3</p>
            <img class="iconMobile" src={kort}></img>

            <p class="cardInfo">
              Betal med bankkort og motta en bekreftelse på e-post/SMS med dato,
              tidspunkt og lenke til din videotime.
            </p>
          </div>

          <div class="step step4">
            <p class="stepNr">4</p>
            <img class="iconMobile" src={person}></img>

            <p class="bookInfo">
              Når det er tid for din time, starter du videokonsultasjonen ved å
              trykke på lenken du fikk tilsendt. Videorommet åpnes i en
              nettleser på PC, mobil eller nettbrett. Husk å akseptere tilgang
              til video og mikrofon.
            </p>
          </div>
        </div>
        <button class="btnBookWhyUs2">
          <Link class="btnBook" to="/booking" id="BookTimeLinkSecond">
            Book time
          </Link>
        </button>
        <p className="howitWorksPitch">
          Vi ser frem til å gi deg en enestående opplevelse,
          <br></br>og hjelpe deg med å få så sunne og sterke tenner som mulig.
        </p>
        <p class="FaqSection">
          <Link to="/faq">
            Lurer du på noe? Les svar på ofte stilte spørsmål her
          </Link>
        </p>
      </div>

      <div class="articlesSection">
        <h2 class="sectionHeadderPill">
          <Link to="/artikler/oversikt">Fagartikler </Link>
        </h2>
        <ArticlesReel />
      </div>

      <div class="aboutUsSection">
        <h2 class="sectionHeadder inbetweenSectionHeadder">Om Abra</h2>

        <p>Velkommen til Abra Dental!</p>
        <p>
          Vi i Abra ønsker at alle skal ha tilgang til tannlegetjenester av høy
          kvalitet, og at alle skal ha et tilbud de faktisk ønsker og kan
          benytte uavhengig av økonomi, hvor man bor eller hva man jobber med.
          Alt vi gjør er med mål om at dine tenner skal være så sunne og sterke
          som mulig.
        </p>
        <p>
          Team Abra består av noen av Norges mest erfarne tannleger, med tung
          faglig bakgrunn og et genuint ønske om at du skal ha en enestående
          opplevelse med våre tjenester. I all kontakt med oss kan du stole på
          at du får faglig bunnsolide råd og veiledning.
        </p>

        <Link to="/omoss">
          <button id="startSection" class="btnReadMore">
            Les mer om Abra
          </button>
        </Link>
      </div>

      <div class="partnersSection">
        <h2 class="sectionHeadderPill">Våre samarbeidspartnere</h2>
        <div class="imagesPartners">
          <img src={PartnerExuma}></img>
          <img src={MicrosoftStartup}></img>
          <img src={Daily}></img>
          <img src={AG}></img>
        </div>
      </div>

      <div class="contactUsSection">
        <ContactUs />
      </div>
      {/* </div> */}
    </HeadProvider>
  );
}
