import { Link, useNavigate } from "react-router-dom";
import "../../css/AdminPage.css";
import { useRef, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import checkUser from "../../components/checkUser.js";

export default function DashboardDentist() {
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["id"]);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [loggedInId, setLoggedInId] = useState("");
  const [getAppointments, setGetAppointments] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const myElementRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    checkUser("dentistDashboard");
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}` + "getName",
        {},
        { withCredentials: true }
      )
      .then((result) => {
        // const findUser = result.data.find(obj => obj.iduser === ~~cookies.id)
        
        setLoggedInUser(
          result.data[0].firstName + " " + result.data[0].lastName
        );
        setLoggedInId(result.data[0].iduser)
      });

    setIsMounted(true);
  }, []);

  useEffect(() => {
    let interval;
    if (!getAppointments) {
      interval = setInterval(() => {
        getSelectedAppointments();
        setGetAppointments(true);
      }, 60);
    }

    if (getAppointments) {
      interval = setInterval(() => {
        getSelectedAppointments();
      }, 6000);
    }

    return () => clearInterval(interval);
  });
  // }, [selectedAppointments]); /*retrieve all the time*/

  async function getSelectedAppointments() {
    let dateNow = new Date();
    let sendDate = `${dateNow.getFullYear()}-0${
      dateNow.getMonth() + 1
    }-${dateNow.getDate()}`;
    
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "getSelectedAppointments", {
        dateNow: sendDate,
      })
      .then((result) => {
        
        setSelectedAppointments(result.data);
      });
  }

  async function finishAppointment(id) {
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "finishBooking", {
        id: id,
        processed: 1,
      })
      .then((result) => {
        
      });
  }

  async function sendActive(e, appointment) {
    if (appointment.active == 1) {
      e.preventDefault();
    }

    
    if (appointment.active != 1) {
      axios
        .post(`${process.env.REACT_APP_APP_URL}` + "updateBooking", {
          id: appointment.idbookings,
          active: 1,
          dentist: loggedInId,
        })
        .then((result) => {
          // window.location.reload();
          
          let interval2 = setInterval(() => {
            finishAppointment(appointment.idbookings);
          }, 1800);

          return () => clearInterval(interval2);
        });
    }
  }
  return (
    <div class="dashDentistPage">
      <h1 class="sectionHeadder">Her er dagens timer, {loggedInUser}!</h1>

      <div class="dashDentistCont">
        <h2>Dagens Videosamtaler</h2>

        {selectedAppointments.map((appointment) => {
          let hrefLink = `${appointment.link}/?t=${appointment.meetingToken}`;

          return (
            <div id={appointment.idbookings} key={appointment.idbookings}>
              <div
                class="pasientCont"
                style={{
                  background:
                    appointment.active == 1 ? "var(--clr-taken)" : "#f5f7fa"
                  }}
              >
                <p class="dashPas-name">
                  {appointment.fname} {appointment.lname}
                </p>
                <p class="dashPas-time">{appointment.time.slice(0, appointment.time.length - 3)}</p>
                <p class="dashPas-phone">{appointment.telephone}</p>
                <p class="dashPas-link">
                  <a
                    class={appointment.active == 1 ? "pasientVidLink client-taken" : "pasientVidLink"}
                    href={appointment.active == 1 ? "#" : hrefLink}
                    onClick={(e) => {
                      sendActive(e, appointment);
                    }}
                    target="_blank"
                  >
                    Videosamtale
                  </a>
                </p>
                <p class="dashPas-dob">{appointment.dob}</p>
                <p class="dashPas-email">{appointment.email}</p>
                <p class="dashPas-address">{appointment.address}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
