import "./css/App.css";
import { AuthProvider } from "./context/AuthContext.js";
import { Routes, Route } from "react-router-dom";
import React from "react";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import Landingpage from './pages/Landingpage.js';
import AboutUs from "./pages/AboutUs.js";
import ArticlesReel from "./components/ArticlesReel.js";
import ContactUs from "./components/ContactUs.js";
import Article from "./pages/Articles/Article.js";
import ArticlesPage from "./pages/Articles/Articles.js";
import CreateArticle from "./pages/Articles/CreateArticle.js";
import BookingPage from "./pages/Booking/BookingPage.js";
import Bookings from "./pages/Booking/Bookings.js";
import Employee from "./pages/Employees/Employee.js";
import EmployeePage from "./pages/Employees/EmployeePage.js";
import EmployeesPage from "./pages/Employees/Employees.js";
import Login from "./pages/Business/Login.js";
import CreateUser from "./pages/Business/CreateUser.js";
import DashboardAdmin from "./pages/Dashboard/DashboardAdmin.js";
import DashboardDentist from "./pages/Dashboard/DashboardDentist.js";
import Privacy from "./pages/HelpSection/Privacy.js";
import Faq from "./pages/HelpSection/Faq.js";
import ForgottenPassword from "./pages/Business/ForgottenPassword.js";
import ResetPassword from "./pages/Business/ResetPassword.js";
import Services from "./pages/Booking/Services.js";
import ThankYou from "./pages/Booking/ThankYou.js";
import ViewArticles from "./pages/Articles/ViewArticles.js";
import Notfound from "./pages/Notfound/Notfound.js";
// import Video from "./pages/video/Video.js";



function App() {
  return (
    <AuthProvider>
      <div className="wrapper">
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Landingpage />} />
          <Route path="/omoss" exact element={<AboutUs />} />
          <Route path="/artikler" exact element={<ArticlesReel />} />
          <Route path="/artikler/:id" exact element={< Article/>} />
          <Route path="/artikler/oversikt" exact element={< ArticlesPage/>} />
          <Route path="/createArticle/" exact element={< CreateArticle/>} />
          <Route path="/viewArticles/" exact element={< ViewArticles/>} />
          <Route path="/ansatte/:id" exact element={< Employee/>} />
          <Route path="/ansatte/" exact element={< EmployeePage/>} />
          <Route path="/ansatte/oversikt" exact element={< EmployeesPage/>} />
          <Route path="/booking" exact element={< BookingPage/>} />
          <Route path="/bookings" exact element={< Bookings/>} />
          <Route path="/kontakt" exact element={<ContactUs />} />
          <Route path="/logginn" exact element={< Login/>} />
          <Route path="/lagbruker" exact element={<CreateUser />} />
          <Route path="/resettPassord" exact element={<ResetPassword />} />
          <Route path="/glemtpassord" exact element={<ForgottenPassword />} />
          <Route path="/dashboardAdmin" exact element={<DashboardAdmin />} />
          <Route path="/dashboard" exact element={<DashboardDentist />} />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/tjenester" exact element={<Services />} />
          <Route path="/datoValg" exact element={<Faq />} />
          <Route path="/vellykket-betaling" exact element={<ThankYou />} />
          <Route path="*" exact element={<Notfound />} />
          {/* <Route path="/video/:id" exact element={<Video />} /> */}
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
