import React from "react";
import "../../css/FormStyle.css"
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import bcrypt from "bcryptjs";
import { useState, useEffect } from "react";


export default function ResetPassword() {
  const navigate = useNavigate();
  const [emailFromDOM, setEmailFromDOM] = useState();

  var salt = bcrypt.genSaltSync(10);

  const schema = yup.object().shape({
    passord: yup.string().required("Vennligst skriv inn nytt passord"),
    // email: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let email1 = window.location.search;
    
    setEmailFromDOM(email1.slice(1,))
    
  })  

  //todo sette dette opp i backenden for å lage en bruker
  async function onSubmit(data) {
    
    let isPassword = await bcrypt.hashSync(data.passord, salt);

    axios
      .post(`${process.env.REACT_APP_APP_URL}`+"changePassword", {
        password: isPassword,
        email: emailFromDOM,
      })
      .then(async (response) => {

        
        //sender respons fra backend som sier at det er vellykket, da kommer man inn til dashboardet
        if (response.data.affectedRows === 1) {
          
          document.getElementById("feedback").innerHTML = "Passord endret!"
          setInterval(() => {
            window.location.replace("/logginn");
          }, 6000)
        }
      });
  }

  return (
    <div class="createUserPage">
      <div class="createUserPageCont">
        <h1 class="sectionHeadder">Endre passord</h1>
        
        <form id="login_form" onSubmit={handleSubmit(onSubmit)}>
          <label class="formLabel sr-only">Passord</label>
          <input class="formInput" {...register("passord")} type="password" placeholder="Passord"/>
          {/* <input class="formInput" {...register("email")} type="hidden" value={"monhelle@gmail.com"}/> */}
          {errors.passord && <span className='form_error'>{errors.passord.message}</span>}

          <div class="loginActions">
            <input class="formBtn" type="submit" value="Endre passord"></input>
          </div>
        </form>
        <div id="feedback"></div>
      </div>
    </div>
  );
};
