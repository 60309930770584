import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../css/TannlegeReel.css";
// import "../img/image002.png"
import dentistDefault from "../img/dentistdefault.avif";
import { HeadProvider, Title, Meta } from "react-head";
import { Link } from "react-router-dom";

export default function TannlegeReel() {
  const [itemsOfEmployees, setItemsOfEmployees] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIsMobile = () => {
      const isMobileDevice = window.innerWidth <= 1000; // Adjust the breakpoint as needed
      setIsMobile(isMobileDevice);
    };

    // Initial check
    checkIsMobile();

    // Event listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // Tldr, hvorfor refractoring av koden: For å ha mulighet til å knytte dem opp med en "dots navigation", trengte jeg å bruke states ordentlig. Noe som ikke ble gjort i den "gamle" koden, hvor "slideIndex" ble behandlet som en vanlig Var statement.
  // Nå utnyttes det ordentlig at alt blir hentet ut en gang fra databasen, og lagret i en State. Når man ruller gjennom nå, eksisterer egentlig bare 1 div, hvor vi bytter ut teksten i - kontra før hvor vi skiftet "display: " gjennom CSS, og viste 1 av 6(max) ant profiler.

  useEffect(() => {
    fetchEmployees();
  }, []);

  

  async function fetchEmployees() {
    axios.post(`${process.env.REACT_APP_APP_URL}getDentistsSlider`).then((result) => {
      setItemsOfEmployees(result.data);
    });
  }

  function plusSlides(n) {
    const newIndex =
      (slideIndex + n + itemsOfEmployees.length) % itemsOfEmployees.length;
    setSlideIndex(newIndex);
  }
  return !isMobile ? (
    <HeadProvider>
      <Title>Abra - våre tannleger</Title>
      <Meta
        name="description"
        content="Her ser du Abra Dentals dyktige tannleger, blant annet Norges ledende fagperson innenfor allmenn tannhelse"
      />
  
      <div class="reelCont reelAnimation" id="tannlegeReel">
        {itemsOfEmployees.length > 0 && (
          <div class="reel">
            {itemsOfEmployees.slice(slideIndex, slideIndex + 3).map((item) => (
              <Link to="/ansatte/oversikt" key={item.id}>
                <div class="empReelCont">
                  {item.img ? (
                    <img
                      class="empReelImg"
                      src={item.img}
                      width="250px"
                      alt="Slide"
                    />
                  ) : (
                    <img
                      class="empReelImg"
                      src={dentistDefault}
                      width="250px"
                      alt="Slide"
                    />
                  )}
  
                  <p class="empReelNames">
                    {item.firstName} {item.lastName}
                  </p>
                  {/* {item.description && (
                    <p class="empReelTxt">
                      {item.description.slice(0, 180)}...
                    </p>
                  )} */}
                </div>
              </Link>
            ))}
          </div>
        )}
  
        <div class="dotsCont">
          {itemsOfEmployees.map((item, index) => {
            const dotClass =
              index >= slideIndex && index < slideIndex + 3
                ? "dentistReelDot dentistReelDotCurrent"
                : "dentistReelDot";
            return <div class={dotClass} key={item.id}></div>;
          })}
        </div>
  
        <div class="arrowCont">
          <a class="prev" onClick={() => plusSlides(-1)}>
            &#10088;
          </a>
          <a class="next" onClick={() => plusSlides(1)}>
            &#10089;
          </a>
        </div>
      </div>
    </HeadProvider>
  ) : 
  
   (
    <HeadProvider>
      <Title>Abra - våre tannleger</Title>
      <Meta
        name="description"
        content="Her ser du Abra Dentals dyktige tannleger, blant annet Norges ledende fagperson innenfor allmenn tannhelse"
      />

      <div class="reelCont reelAnimation" id="tannlegeReel">
        {itemsOfEmployees.length > 0 && (
          <div class="reel" id={itemsOfEmployees[slideIndex]}>
            <Link to="/ansatte/oversikt">
            <div class="empReelCont">
              {itemsOfEmployees[slideIndex].img ? (
                  <img
                    class="empReelImg"
                    src={itemsOfEmployees[slideIndex].img}
                    width="250px"
                    alt="Slide"
                  />
                  ) : (

                  <img
                    class="empReelImg"
                    src={dentistDefault}
                    width="250px"
                    alt="Slide"
                    />

              )}

              <p class="empReelNames">
                {itemsOfEmployees[slideIndex].firstName}{" "}
                {itemsOfEmployees[slideIndex].lastName}
              </p>
              {/* <p class="empReelTxt">{itemsOfEmployees[slideIndex].description} </p> */}
              {/* {itemsOfEmployees[slideIndex].description && (
                <p class="empReelTxt">
                  {itemsOfEmployees[slideIndex].description.slice(0, 180)}...
                </p>
              )} */}
            </div>
            </Link>
          </div>
        )}

        <div class="dotsCont">
          {itemsOfEmployees.map((item) => {
            if (itemsOfEmployees.indexOf(item) === slideIndex) {
              return <div class="dentistReelDot dentistReelDotCurrent"> </div>;
            } else {
              return <div class="dentistReelDot"> </div>;
            }
          })}
        </div>

        <div class="arrowCont">
          <a class="prev" onClick={() => plusSlides(-1)}>
            &#10088;
          </a>
          <a class="next" onClick={() => plusSlides(1)}>
            &#10089;
          </a>
        </div>
      </div>
    </HeadProvider>
  );

}
