import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { date } from "yup";
import Cookies from "universal-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function CheckoutForm({ info, price }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    document.getElementById("paymentButton").innerHTML = "Vennligst vent";

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      document.getElementById("errorMessage").innerHTML = result.error.message;
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // let chosenDate1 = new Date

        // let chosenDate2 = info.selchectDate.toISOString().slice(0, 10);

        document.getElementById("paymentButton").innerHTML =
          "Oppretter video-chat lenke";

        axios
          .post(`${process.env.REACT_APP_APP_URL}` + "createBooking", {
            firstName: info.fname,
            lastName: info.lname,
            email: info.email,
            dob: info.dob,
            address: info.address,
            telephone: info.telephone,
            chosenDate: info.chosenDate,
            chosenTime: info.selectTime,
          })
          .then(async (result) => {
            try {
              await createRoom(
                info.chosenDate,
                info.selectDate,
                info.selectTime,
                result.data.insertId,
                info.email
              );
            } catch (error) {
              console.log(error);
            }
            // CheckoutForm();
          });
      }

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  if (success) {
    return (
      <div className="payment-confirmation">
        Betalingen er godkjent. Du får snart en bekreftelse via epost.
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} class="checkoutModalInner">
      <PaymentElement />
      <button id="paymentButton" class="formBtn paymentBtn" disabled={!stripe}>
        Betal med Stripe
      </button>
    </form>
  );

  async function createRoom(
    chosenDate,
    UTCDate,
    selectedTime,
    insertId,
    emailUser
  ) {
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "createRoom", {
        chosenDate: info.chosenDate,
        UTCDate,
        selectedTime,
        insertId,
        emailUser,
        price,
      })
      .then((result) => {
        if (result.status == 200) {
          document.querySelector(".feedbackBooking").innerHTML = `Vellykket.`;
          setInterval(() => {
            document.querySelector(".checkoutModal").style.display = "none";
            window.location.href = `${process.env.REACT_APP_CURRENT_APP_URL}vellykket-betaling`;
          }, 1800);

        } else if(result.data.errorMsg) {
          document.querySelector(".feedbackBooking").innerHTML =
          result.data.errorMsg;

        }
        else {
          document.querySelector(".feedbackBooking").innerHTML =
            result.data;
        }
      });
  }
}
