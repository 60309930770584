import "../css/Footer.css";
// import headerLogo from "../img/AbraLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as AbraLogo } from "../img/AbraLogo.svg";

export default function Footer(params) {
  const [cookies, setCookie] = useCookies();
  const [name, setName] = useState("");
  const [loggedInUser, setLoggedInUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (name.length <= 0) {
        getName();
      }
      
    }, 60000);

    
  });


  useEffect(() => {
    getCookie();
  }, [])
  async function getCookie() {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}cookies`,
        {},
        { withCredentials: true }
      )
      .then((result) => {
        if (result.data.id) {
          setCookie({ id: result.data.id });
          setIsLoggedIn(true);
        }
      });
  }

  async function getName() {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}getName`,
        { id: 5 },
        { withCredentials: true }
      )
      .then((result) => {
        setName(`${result.data[0].firstName} ${result.data[0].lastName}`);
        setLoggedInUser(
          `${result.data[0].firstName} ${result.data[0].lastName}`
        );
      });
  }
  // useEffect(() => {
  //   axios.post("http://localhost:4000/getDentists").then((result) => {
  //     const findUser = result.data.find(obj => obj.iduser === ~~cookies.id)
  //     setLoggedInUser(findUser.firstName)
  //   });
  // }, []);

  const userLogOut = () => {
    let cookieInfo = ["id", "role"];
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}removeCookie`,
        { cookieInfo },
        { withCredentials: true }
      )
      .then((result) => {
        if(result.data) {
          document.getElementById("LoggUtLink").innerHTML = "Logg inn"
        }
      });
  };

  return (
    <div class="footer">
      {/* <p class="loggedInIndikator">{cookies.id ? `Du er logget inn som ${name}` : ``}</p> */}
      <p class="loggedInIndikator">
        {cookies.id ? `Du er logget inn som ${loggedInUser}` : ``}
      </p>

      <ul class="footerCont">
        <li className="footerLogo">
          <Link to="/">
            <AbraLogo />
            {/* <img */}
              {/* src={headerLogo} */}
              {/* alt="corporate logo for Abra dental service" */}
            {/* ></img> */}
            <p>Din digitale tannlege</p>
          </Link>
        </li>
        <li>
          <Link class="footerLink" to="/privacy" id="PersonvernLink">
            Personvern
          </Link>
        </li>
        <li>
          <Link class="footerLink" to="/booking" id="BookTimeLinkFooter">
            Book time
          </Link>
        </li>
        <li>
          <Link class="footerLink" to="/kontakt" id="KontaktLink">
            Kontakt oss
          </Link>
        </li>
        {/* <li><Link class="footerLink" to="/faq" id="FaqLink">Faq</Link></li> */}
        {cookies.id ? (
          <li>
            <Link class="footerLink" to="/dashboard" id="DashBoardLink">
              Dagens timer
            </Link>
          </li>
        ) : (
          <li>
            <Link class="footerLink" to="/faq" id="FaqLink">
              FAQ
            </Link>
          </li>
        )}
        <li>
          <Link class="footerLink" to="/ansatte/oversikt" id="AnsatteLink">
            Våre tannleger
          </Link>
        </li>
        {isLoggedIn ? (
          <li>
            <Link
              onClick={userLogOut}
              class="footerLink"
              to="/"
              id="LoggUtLink"
            >
              Logg ut
            </Link>
          </li>
        ) : (
          <li>
            <Link class="footerLink" to="/logginn" id="LoggInnLink">
              Logg inn
            </Link>
          </li>
        )}
        {/* <li><Link class="footerLink" to="/logginn" id="LoggInnLink">Logg inn</Link></li> */}
      </ul>
    </div>
  );
}
