import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";



export default function ViewArticles() {

  const [articles, setArticles] = useState([]);



  useEffect(() => {
    letsGetTheBlogs();
  })

  function letsGetTheBlogs() {
    
    if (articles <= 0 || articles === "undefined") {
      axios
        .post(`${process.env.REACT_APP_APP_URL}` + "getAllTheBlogs")
        .then((response) => {

          
          setArticles(response.data);
        });
    }
  }

  async function deleteArticle(e) {

    let blogID = e.target.id;
    axios
    .post(`${process.env.REACT_APP_APP_URL}` + "deleteBlog", {blogID})
    .then((response) => {
      document.getElementById("feedbackDelete").innerHTML = response.data;

      window.location.reload();
      
    });

  }

  return (
    <div class="createArticlePage">
      {" "}
      <div class="employeeTrailCont">
        <Link to="/dashboardAdmin">Gå til admin-dashboard</Link>
        <Link to="/dashboard">Gå til tannlegesiden</Link>
      </div>
      <h1 class="sectionHeadder">Se alle artikler</h1>
      <div class="selectedArticle">

    {articles.map((article, index) => {
      return(
        <div class="deleteArticle">
        <p>{article.title}</p>
        <p class="deleteButton" id={article.idblogg} onClick={((e) => deleteArticle(e))}>Slett</p>
        <p id="feedbackDelete"></p>
        </div>
        
        )
      })}
      </div>
      </div>
   
      );
};
