import "../css/Navbar.css";
import { ReactComponent as AbraLogo } from "../img/AbraLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleWindowResize = () => {
  //   if (window.innerWidth > 750) {
  //     setIsDropdownOpen(false);
  //   }
  // };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)

  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  async function handleClose(e) {
    if(e.target.id !== "MenuBtn") {
      setIsDropdownOpen(false)
    }
  }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowResize);
  //   return () => window.removeEventListener("resize", handleWindowResize);
  // }, []);

  useEffect(() => {
    document.addEventListener('click', handleClose);
  }, [])
  

  return (
    <nav className="header" id="Navbar">
      <ul className="fullNavCont">
        <li className="header-logo">
          <Link to="/">
            <AbraLogo className="abra-Logo"/>
            <p className="logoText">Din digitale tannlege</p>
          </Link>
        </li>

        <li className="navBookBtnCont">
          <Link className="btn-white maxWidthNav" to="/booking" id="OmOssBtn">
            Book time
          </Link>
        </li>



        <li>
          <Link
            className="btn-white minWidthNav"
            id="MenuBtn"
            onClick={toggleDropdown}
          >
            <span
              className={isDropdownOpen ? "menuDeco menuDecoAnim" : "menuDeco"}
            ></span>
            Meny
          </Link>
        </li>

        <ul
          className="mobileNavCont"
          style={isDropdownOpen ? { display: "flex" } : { display: "none" }}
        >
          {/* <li className="mobileNavBookBtn">
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/booking"
              id="BookingBurgerBtn"
            >
              Book time
            </Link>
          </li> */}

          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/booking"
              id="BookTimeBurgerBtn"
            >
              Book time
            </Link>
          </li>
          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/omoss"
              id="OmOssBurgerBtn"
            >
              Om Abra
            </Link>
          </li>

          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/tjenester"
              id="TjenesterBurgerBtn"
            >
              Tjenester
            </Link>
          </li>
          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/artikler/oversikt"
              id="ArtiklerBurgerBtn"
            >
              Fagartikler
            </Link>
          </li>

          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/kontakt"
              id="KontaktBurgerBtn"
            >
              Kontakt
            </Link>
          </li>

          <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/faq"
              id="KontaktBurgerBtn"
            >
              Ofte stilte spørsmål
            </Link>
          </li>

          {/* <li>
            <Link
              className="mobileNavBtn"
              onClick={closeDropdown}
              to="/Tjenester"
              id="TjenesterBurgerBtn"
            >
              Tjenester
            </Link>
          </li> */}
        </ul>

      </ul>
    </nav>
  );
}
