import axios from "axios";

export async function getPrice() {
  try {
    const response = await axios.post(`${process.env.REACT_APP_APP_URL}getPrice`);
    const price = response.data[0].price;
    return price;
  } catch (error) {
    console.error(error);
    throw error; 
  }
};
