import React from "react";
import "../../css/FormStyle.css";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function ForgottenPassword() {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().required("Vennligst skriv inn emailen din"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //todo sette dette opp i backenden for å lage en bruker
  async function onSubmit(data) {
    
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "resetPassword", {
        email: data.email,
      })
      .then((response) => {
        
        //sender respons fra backend som sier at det er vellykket, da kommer man inn til dashboardet
        if (response.status === 200) {
          document.getElementById("feedbackReset").innerHTML = "Dersom du har en bruker hos oss skal du nå ha fått en tilbakestillingslenke."
          setInterval(() => {
            navigate("/logginn");

          }, 1500)
        }
      });
  }

  return (
    <div class="createUserPage">
      <div class="createUserPageCont">
        <h1 class="sectionHeadder">Tilbakestill passord</h1>

        <form id="login_form" onSubmit={handleSubmit(onSubmit)}>
          <label class="formLabel sr-only">Epost</label>
          <input
            class="formInput"
            {...register("email")}
            type="text"
            placeholder="Epost"
          />
          {errors.email && (
            <span className="form_error">{errors.email.message}</span>
          )}

          <div class="loginActions">
            <p>
              <Link to="/logginn">Allerede bruker og husker passordet?</Link>
            </p>
            <input
              class="formBtn"
              type="submit"
              value="Tilbakestill passord"
            ></input>
          </div>
        </form>
        <div id="feedbackReset">
          
        </div>
      </div>
    </div>
  );
}
