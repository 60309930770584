import axios from "axios";

export default function checkUser(pathparam) {
  axios
    .post(
      `${process.env.REACT_APP_APP_URL}` + "userRole",
      { path: pathparam },
      { withCredentials: true }
    )
    .then((result) => {
      
      if (result.data === "redirect") {
        window.location.href = "/";
      }
    });
}
