import "../../css/ThankYouPage.css"

export default function ThankYou() {
  return (
    <div>
      <h1 class="sectionHeadder">Takk for din henvendelse!</h1>   
      <p class="thankYouText">
      Du vil motta epost med bekreftelse og en lenke til konsultasjonen. </p>
      <p class="thankYouText">Logg gjerne inn 5 minutter før konsultasjonen. </p>
      <p class="thankYouText">Sjekk søppelboks dersom du ikke finner e-posten i vanlig innboks.
      </p>
    </div>
  );
}
