import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { HeadProvider, Title, Meta } from "react-head";
import { useEffect } from "react";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required("Vennligst skriv inn navnet ditt ditt"),
    email: yup.string().required("Vennligst skriv inn eposten din"),
    telefonnummer: yup
      .string()
      .max(8)
      .min(8)
      .required("Vennligst skriv inn eposten din"),
    message: yup.string().required("Vennligst skriv inn beskjeden"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data) {
    //todo må settes opp i backenden for å hente ut bruker
    schema
      .validate(data)
      .then((validatedData) => {
        // Validation succeeded, the validated data is available here

        axios
          .post(`${process.env.REACT_APP_APP_URL}` + "sendMessage", {
            name: data.name,
            email: data.email,
            email: data.telefonnummer,
            message: data.message,
          })
          .then(async (response) => {
            document.getElementById("message").innerHTML = response.data;
          });
      })
      .catch((validationError) => {
        // Validation failed, the error is available here
        console.error(validationError);
      });
  }

  return (
    <HeadProvider>
      <Title>Abra - kontakt oss</Title>
      <Meta
        name="description"
        content="Ønsker du å kontakte Abra Dental? Ta kontakt med oss idag"
      />

      <div class="contactUsCont">
        <h1 class="sectionHeadder inbetweenSectionHeadder">Kontakt oss</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label for="name">Navn</label>
          <input
            {...register("name")}
            type="text"
            id="Name"
            name="name"
            placeholder="Ola Normann"
          ></input>
          {errors.name && (
            <span className="form_error">{errors.name.message}</span>
          )}

          <label for="email">Epost</label>
          <input
            {...register("email")}
            type="text"
            id="Email"
            name="email"
            placeholder="Ola@normann.no"
          ></input>
          {errors.email && (
            <span className="form_error">{errors.email.message}</span>
            )}
            <label for="telefonnummer">Telefonnummer</label>
          <input
            {...register("telefonnummer")}
            type="text"
            id="telefonnummer"
            name="telefonnummer"
            placeholder="55 55 44 33"
          ></input>
          {errors.email && (
            <span className="form_error">{errors.telefonnummer.message}</span>
          )}

          <label for="message">Melding</label>
          <textarea
            {...register("message")}
            type="text"
            id="Message"
            name="message"
            placeholder="Skriv inn din melding her"
          ></textarea>
          {errors.message && (
            <span className="form_error">{errors.message.message}</span>
          )}

          <button class="btnBook">Send</button>
        </form>
        <div id="message"></div>
      </div>
    </HeadProvider>
  );
}
