import "../../css/Article.css";
import headerLogo from "../../img/abraTinyLogo.png";
import axios from "axios";
import { useEffect, useState } from "react";
import DOMParserReact, { parse } from "dom-parser-react";
import Brush from "../../img/HeroTeeth.png";
import { HeadProvider, Title, Meta } from "react-head";
import { Link } from "react-router-dom";

export default function Article() {
  const [title, setTitle] = useState("");
  const [headline, setHeadline] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metatitle, setMetaTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    getBlogArticle();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (body !== "undefined" || body !== "") {
    return (
      <HeadProvider>
        <Title>{metatitle}</Title>
        <Meta name="description" content={metaDesc} />
        <div class="articlePage">
          <img
            class="exampleImage"
            src={heroImage}
          ></img>
          <h1 class="sectionHeadder">{headline}</h1>

          <div class="articleCont">
            <div class="articlesTextCont">
              <DOMParserReact source={body} />
            </div>

            <aside class="articleInfoBox">
              <h3>Snakk med oss</h3>
              <ul>
                <li>
                  <img
                    src={headerLogo}
                    alt=""
                    width="25px"
                    class="articleListIcon"
                  ></img>
                  Rask tilgang til tannlege
                </li>
                <li>
                  <img
                    src={headerLogo}
                    alt=""
                    width="25px"
                    class="articleListIcon"
                  ></img>
                  Få hjelp med dine bekymringer
                </li>
                <li>
                  <img
                    src={headerLogo}
                    alt=""
                    width="25px"
                    class="articleListIcon"
                  ></img>
                  Videokonsultasjon
                </li>
                <li>
                  <img
                    src={headerLogo}
                    alt=""
                    width="25px"
                    class="articleListIcon"
                  ></img>
                  Second opinion
                </li>
              </ul>
              <Link to="/booking"><button class="btnBook">Book nå</button></Link>

            </aside>
          </div>
        </div>
      </HeadProvider>
    );
  } else {
    return (
      <div>
        <h1>Blogg?</h1>
      </div>
    );
  }

  async function getBlogArticle() {
    let path = window.location.pathname;

    let id = path.slice(10);

    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "getBlogArticle", {
        idblogg: id,
      })
      .then((response) => {
        if (response.status === 200) {
          setTitle(response.data[0].title);
          setBody(response.data[0].content);
          setMetaDesc(response.data[0].metaDesc);
          setHeadline(response.data[0].title);
          setMetaTitle(response.data[0].metaTitle);

          if (response.data[0].heroImage !== null) {
            setHeroImage(response.data[0].heroImage);
          } else {
            setHeroImage(Brush);
          }
        }

        // setBody(response.data[0].content);

        // let info = document.getElementById("bodyElement").innerHTML;
        // info += response.data[0].content;
      });
  }
}
