import "../css/AboutUsPage.css";
import "../css/GlobalComponents.css";
import TannlegeReel from "../components/TannlegeReel.js";
import { Link, useNavigate } from "react-router-dom";
import { HeadProvider, Title, Meta } from "react-head";
import { useEffect } from "react";
import Martin from "../img/image002.png";
import OleChristian from "../img/OleChristian.jpg"

export default function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <HeadProvider>
      <Title>Abra - Om Abra</Title>
      <Meta
        name="description"
        content="Abra Dental ble startet med et ønske om å gjøre tannlegen mer tilgjengelig – uavhengig av tid og sted. Over halvparten av Norges befolkning har ikke tilgang til tannlege utenom dagtid mandag-fredag."
      />
      <div>
      {/* <div class="leadership"> */}
        <div class="omOssCont" id="startSection">
          <h1 class="sectionHeadder">Vår visjon</h1>
          <p className="omOssTekst">
            Abra Dental ble startet med et ønske om å gjøre tannlegen mer
            tilgjengelig – uavhengig av tid og sted. Over halvparten av Norges
            befolkning har ikke tilgang til tannlege utenom dagtid
            mandag-fredag, for eksempel til å håndtere akutte tannskader. 1 av 3
            nordmenn går ikke til tannlegen hvert år. Det kan blant annet
            skyldes dårlig råd, utfordringer med arbeid og logistikk eller at
            man tilhører de ~10% av befolkningen som har tannlegeskrekk.{" "}
          </p>
          <p className="omOssTekst">
            Vi i Abra ønsker at alle skal ha tilgang til tannlegetjenester av
            høy kvalitet, og at alle skal ha et tilbud de faktisk ønsker og kan
            benytte uavhengig av økonomi, hvor man bor eller hva man jobber med.
            Alt vi gjør er med mål om at dine tenner skal være så sunne og
            sterke som mulig.
          </p>
          <p className="omOssTekst">
            Team Abra består av noen av Norges
            <Link to="/ansatte/"> mest erfarne tannleger</Link>, med tung faglig
            bakgrunn.
          </p>
        </div>

        <div class="leadershipSection box">
          <h2 class="sectionHeadderPill">Ledelse</h2>
          <div>
            <div class="management">
              <img src="https://bilderabradental.blob.core.windows.net/ledelse/Ole-Christian300px.png" ></img>{" "}
              <div>

              <h2>Ole-Christian Normann</h2>
              <p>Ole-Christian Kreyberg Normann er grunnlegger og daglig leder av Abra Dental</p>

              <p>Epost: <a href="mailto:normann@abradental.no">normann@abradental.no</a></p>
              </div>

            </div>
            <div class="management">
              <img src="https://bilderabradental.blob.core.windows.net/ledelse/Martin300px.png"></img>{" "}
              <div>

              <h2>Martin Christopher Hoftvedt</h2>
              <p>Martin er tannmedisinsk sjef i Abra 
              </p>
              <p>Epost: <a href="mailto:martin@abradental.no">martin@abradental.no</a></p>
              </div>
            </div>
          </div>
        </div>

        <div class="ansatteSection2 box">
          <h2 class="sectionHeadderPill">Våre Tannleger</h2>
          <TannlegeReel />
        </div>
        <div class="omOssCont">
          <h2 class="sectionHeadder">Hvorfor tannlege på video?</h2>
          {/* <h3 class="cardTitle">Hvorfor tannlege på video?</h3> */}
          <p>
            Ved å benytte video kan vi tilby deg tilgang til tannlege uavhengig
            av tid på døgnet og hvor du er i verden. Dette er første skritt på
            vår vei mot å gjøre tannlegen mer tilgjengelig for alle. På denne
            måten kan vi nå deg hjemme i sofaen, på jobb, på hytta eller på
            reise utenlands. Vi er tilgjengelige når det passer for deg.
          </p>
          <p>
            Våre tannleger får daglig telefoner i sin praksis med diverse
            spørsmål. Veldig mange har ikke den muligheten hos sin tannlege, og
            med video vil man få mye mer informasjon enn over telefon.
          </p>
          <p>
            Det er naturligvis noe begrenset hva slags undersøkelser og
            behandling en tannlege kan utføre digitalt, men i de fleste
            tilfeller vil vi få god nok oversikt over situasjonen til å kunne gi
            gode råd og veiledning, og igangsette behandling med smertestillende
            eller antibiotika i noen tilfeller der det er nødvendig.
          </p>
        </div>
        {/* <div class="omOssCont"> */}
          {/* <h2 class="sectionHeadder">Daglig leder</h2> */}
          {/* <h3>Daglig leder</h3> */}
          {/* <p>
            Ole-Christian Kreyberg Normann er grunnlegger og daglig leder av
            Abra Dental. Ole-Christian ønsker å bruke sin erfaring som lege,
            konsulent og leder i flere helserelaterte oppstartsbedrifter til å
            utjevne forskjeller og bedre tannlegetilbudet for alle.
          </p> */}
          {/* Sett inn bilde av Ole Christian */}
          {/* <img src=""></img> */}
        {/* </div> */}
      </div>
    </HeadProvider>
  );
}
