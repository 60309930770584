import "../../css/ArticlesPage.css";
import { useState, Route } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DOMParserReact, { parse } from "dom-parser-react";
import Brush from "../../img/HeroTeeth.png";
import { HeadProvider, Title, Meta } from "react-head";

export default function Articles() {
  const [bloggList, setBloggList] = useState([]);
  let blogs = letsGetTheBlogs();

  return (
    <HeadProvider>
      <Title>Abra - Fagartikler</Title>
      <Meta
        name="description"
        content="Abra Dental har en rekke nyttige artikler som hjelper deg med å få de rådene du trenger mht tannhygiene"
      />

      <div class="articlesPage">
        <h1 class="sectionHeadder">Fagartikler</h1>

        <div class="articlesCont">
          {bloggList.map((blogg, index) => {
            let azureImg = false;
            
            if (blogg.heroImage !== null) {
              azureImg = true;
            }
            return (
              <div class="articleCard">
                <div class="articleCardImgCont">
                  {azureImg ? (
                    <img
                      class="articleCardImg"
                      src={blogg.heroImage}
                      alt=""
                    ></img>
                  ) : (
                    <img class="articleCardImg" src={Brush} alt=""></img>
                  )}
                </div>
                <h3>{blogg.title}</h3>
                <DOMParserReact source={blogg.content.slice(3, 185) + " ..."} />
                {/* <p class="articleCardText">{blogg.content.slice(3, 250)}...</p> */}
                <p class="lesMerBtn">
                  <Link className="cta" to={"/artikler/" + blogg.idblogg}>
                    Les mer
                  </Link>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </HeadProvider>
  );

  function letsGetTheBlogs() {
    
    if (bloggList <= 0 || bloggList === "undefined") {
      axios
        .post(`${process.env.REACT_APP_APP_URL}` + "getAllTheBlogs")
        .then((response) => {
          
          setBloggList(response.data);
        });
    }
  }
}
