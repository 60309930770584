import { useEffect, useState } from "react";
import "../../css/AdminPage.css";
import "../../css/EmployeePage.css";
import axios from "axios";
import checkUser from "../../components/checkUser.js";
import { saveAs } from "file-saver";
import * as xlsx from "xlsx";
import { Link } from "react-router-dom";

export default function Bookings() {
  const [allBookings, setAllBookings] = useState([]);

  useEffect(() => {
    getAllBookings();
    // setInterval(() => {
    //   checkUser("bookings");
    // }, 180000);
  }, []);

  useEffect(() => {
    checkUser("bookings")
  })

  async function getAllBookings() {
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "getAllBookings")
      .then((result) => {
        setAllBookings(result.data);
      });
  }

  const downloadExcel = async () => {
    // Fetch data from the backend (replace with your API endpoint)
    // const data = allBookings.json();

    // Prepare the workbook
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(allBookings);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    let now = Date.now();
    // Generate a unique filename for the downloaded file
    const filename = `bookinger${now}.xlsx`;

    // Save the workbook as an Excel file and trigger the download
    xlsx.writeFile(workbook, filename);
  };

  return (
    <div class="employeePage">
      <div class="employeeTrailCont">
        <Link to="/dashboardAdmin">Gå til admin-dashboard</Link>
        <Link to="/dashboard">Gå til tannlegesiden</Link>
      </div>
      
      <h1 class="sectionHeadder">Her ser du alle bookinger som er gjennomført</h1>
      <button class="btnBook excelBtn" onClick={downloadExcel}>
        Last ned Excel
      </button>

      <div class="employeeCardCont illustrasjonsCont">
        <div class="cardBookings">
          <p>Navn</p>
          <p>Dato for timen</p>
          <p>Tidspunkt</p>
          <p>Telefon</p>
          <p class="bookingCardBottom bookingCardEmail">Epost</p>
          <p class="bookingCardBottom">Fødselsdato</p>
          <p class="bookingCardBottom ">Adresse</p>
          <p class="bookingCardBottom ">Tannlege</p>
          {/* <p>Tannlege</p> */}
        </div>
      </div>

      <div>
        {allBookings.map((appointment) => {

          let appointmentDate = new Date(appointment.date).toLocaleDateString(
            "no-NO"
          );
          return (
            <div class="employeeCardCont" id={appointment.id}>
              <div class="cardBookings">
                <p>
                  {" "}
                  {appointment.fname} {appointment.lname}
                </p>
                <p>{appointmentDate}</p>
                <p>{appointment.time.slice(0, -3)}</p>
                <p>{appointment.telephone}</p>
                <p class="bookingCardBottom bookingCardEmail">{appointment.email}</p>
                <p class="bookingCardBottom">{appointment.dob}</p>
                <p class="bookingCardBottom" id="address">{appointment.address}</p>
                <p class="bookingCardBottom">{appointment.dentistName}</p>
                <p></p>
              </div>
            </div>
          );
        })}
      </div>

      <h1 class="sectionHeadder">Her ser du alle bookinger som er gjort</h1>

    </div>
  );
}
