import React from "react";
import ReactDOM from "react-dom/client";
import "./css/Reset.css";
import "./css/Variables.css";
import "./css/LandingTypography.css";
import "./css/index.css";
import "./css/GlobalComponents.css"
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
