import "../../css/FormStyle.css";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import bcrypt from "bcryptjs";

export default function Login() {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [role, setRole] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    getCookie();
  }, []);

  useEffect(() => {
    // navigateUser();
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function getCookie() {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}cookies`,
        {},
        { withCredentials: true }
      )
      .then((result) => {
        if (result.data.id) {
          setId(result.data.id);
          setRole(result.data.role);
          setIsLoggedIn(true);
        }
      });
  }

  function navigateUser() {
    if (id && role == 1) {
      navigate("/dashboardAdmin");
    } else if (id && role == 2) {
      navigate("/dashboard");
    } else if (id && role == 3) {
      navigate("createArticle");
    }
  }


  var salt = bcrypt.genSaltSync(10);

  //hente ut info fra brukernavn og passord
  const schema = yup.object().shape({
    passord: yup.string().required("Vennligst skriv inn passordet"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  async function onSubmit(data) {
    axios
      .post(
        `${process.env.REACT_APP_APP_URL}` + "getUser",
        {
          username: data.brukernavn,
        },
        { withCredentials: true }
      )
      .then(async (response) => {
        let isPassword;
        let hashedPwd;

        if (response.data.errorMsg) {
          document.getElementById("error").innerHTML = response.data.errorMsg;
        } else {
          hashedPwd = response.data.HP;
          setId(response.data.id);
          setRole(response.data.role);
          //sammenligne passord
          isPassword = await bcrypt.compare(data.passord, hashedPwd);
          // document.getElementById("LoggInnLink").innerHTML = "Logg ut";
        }

        if (isPassword) {
          axios
            .post(
              `${process.env.REACT_APP_APP_URL}` + "setCookie",
              {
                id: response.data.id,
                role: response.data.role,
              },
              { withCredentials: true }
            )
            .then((result) => {
              if (result.status === 200) {
                if (
                  isPassword &&
                  response.data.role === 1 &&
                  response.data.accepted === 1
                ) {
                  //sende til admin bruker dashboard
                  navigate("/dashboardAdmin");
                } else if (
                  isPassword &&
                  response.data.role === 3 &&
                  response.data.accepted === 1
                ) {

                  //sende til tannlegedashboard
                  navigate("/dashboard");
                } else if (isPassword && response.data.role === 2) {
                  navigate("/createArticle");
                } else if (
                  isPassword &&
                  response.data.role === 3 &&
                  response.data.accepted === 0
                ) {
                  //sende til tannlegedashboard
                  document.getElementById("loginPwCont").placeholder =
                    "Du må bli bekreftet først av administrator";
                  document.getElementById("loginPwCont").value = "";
                  document
                    .getElementById("loginPwCont")
                    .classList.add("form_error");
                }
              }
            });
        } else {
          document.getElementById("loginPwCont").placeholder =
            "Feil brukernavn eller passord";
          document.getElementById("loginPwCont").value = "";
          document.getElementById("loginPwCont").classList.add("form_error");
        }
      });
  }

  return (
    <div class="loginPage">
      <div class="loginPageCont">
        <h1 class="sectionHeadder">Logg inn</h1>

        <form id="login_form" onSubmit={handleSubmit(onSubmit)}>
          <label class="formLabel sr-only" htmlFor="brukernavn">
            Epost
          </label>
          <input
            class="formInput"
            type="text"
            placeholder="Epost"
            {...register("brukernavn")}
          />

          <label class="formLabel sr-only" htmlFor="passord">
            Passord
          </label>
          <input
            class="formInput"
            id="loginPwCont"
            {...register("passord")}
            type="password"
            placeholder="Passord"
          />

          <div class="loginActions">
            <div class="loginActionsCont">
              <p>
                <Link to="/lagbruker">Ny bruker?</Link>
              </p>
              <p>
                <Link to="/glemtpassord">Glemt passord?</Link>
              </p>
            </div>

            <input class="formBtn" type="submit" value="Logg inn"></input>
          </div>
        </form>

        <div id="error"></div>
      </div>
    </div>
  );
}
