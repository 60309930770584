import "../../css/GlobalComponents.css";
import "../../css/Services.css";
import { useEffect } from "react";

export default function Services() {
  // const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  async function handleClick(e) {
    let id = e.target.parentElement.id;
    let id2 = e.target.parentElement.parentElement.id;

    if (id) {
      if (document.getElementById(id).lastChild.style.display == "block") {
        document.getElementById(id).lastChild.style.display = "none";
        document.getElementById(`arrow-${id}`).style.transform = "rotate(0deg)";
      } else if (
        document.getElementById(id).lastChild.style.display === "none" ||
        document.getElementById(id).lastChild.style.display == ""
      ) {
        document.getElementById(id).lastChild.style.display = "block";
        document.getElementById(`arrow-${id}`).style.transform = "rotate(90deg)";
      }
    } if (id2 !== '') {
      if (document.getElementById(id2).lastChild.style.display == "block") {
        document.getElementById(id2).lastChild.style.display = "none";
        document.getElementById(`arrow-${id2}`).style.transform = "rotate(0deg)";
      } else if (
        document.getElementById(id2).lastChild.style.display === "none" ||
        document.getElementById(id2).lastChild.style.display == ""
      ) {
        document.getElementById(id2).lastChild.style.display = "block";
        document.getElementById(`arrow-${id2}`).style.transform = "rotate(90deg)";
      }

    }



  }

  return (
    <div class="servicesPage">
      <div>
        <div class="serviceHeadderCont" id="SI-01">
          <h1 class="sectionHeadder">Hva kan vi hjelpe deg med </h1>

          <p>
          Vi kan gi råd og veiledning om de aller fleste skader og tilstander relatert til dine tenner, samt beste praksis behandling og hvor mye dette vanligvis vil koste. Dette gjelder også dine barn og andre i din omsorg. Etter samtale med oss møter du den fysiske tannlegen bedre forberedt og informert. Vi veileder deg til den hjelpen som er best egnet for din situasjon.{" "}
          </p>
        </div>
        <div class="faqSection">
          <div class="serviceItemCont" id="SI-02" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Akutte tannskader</h2>
              <span class="arrowSection" id="arrow-SI-02">&#x2769;</span>
            </div>

            <hr></hr>
            <p class="hiddenText">
            Har du knekt enn tann, ødelagt en fylling, eller har du andre spørsmål om uhell knyttet til dine tenner? Spør oss om råd. Vi kan gi deg veiledning til hva du bør gjøre selv, hvor du kan få hjelp, og ikke minst når. Noen skader bør behandles så fort som mulig, andre ganger har man bedre tid. Ved behov kan vi skrive resept på smertestillende medikamenter. Vi skriver som regel ikke ut A- og B-preparater (Paralgin forte o.l.). Dette er vanedannende medikamenter og bør primært forbeholdes fastlegen.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-03" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Barn</h2>
              <span class="arrowSection" id="arrow-SI-03">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Våre tannleger har god erfaring med tenner hos barn, både melketenner og permanente tenner. Vi kan svare på spørsmål om akutte skader, senfølger etter tidligere skader, misfarging, smerter ved fremvekst av tenner, regulering og det mest annet. Vi kan gi god veiledning i utredning og behandling, og hvordan best navigere det offentlige systemet for å få den beste hjelpen. Det er også mange som lurer på ting rundt tannhygiene og tannpuss hos barn. Det hjelper vi deg gjerne med.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-04" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Tannbleking</h2>
              <span class="arrowSection" id="arrow-SI-04">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Lurer du på om tannbleking er trygt? Vi selger ikke tannbleking, men vet at særlig mange unge blir eksponert for et hav av ulike metoder gjennom sosiale medier. Vi ønsker derfor å bidra til at slik egenbehandling i minst mulig grad fører til skader på tennene, og kan anbefale de produktene som er trygge og klinisk veldokumenterte – uten relevante bivirkninger. Ta kontakt med oss for nøytral og faglig fundert veiledning i jakten på det perfekte smilet.{" "}
            </p>
          </div>

          <div class="serviceItemCont" id="SI-05" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Tannregulering</h2>
              <span class="arrowSection" id="arrow-SI-05">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Stadig flere voksne ønsker å rette på skjeve tenner ved hjelp av tannregulering. Det finnes nå flere typer regulering som er lite sjenerende og godt egnet for dette, men er ofte kostbart. Snakk med oss for uavhengig råd og veiledning til tannregulering både for barn og voksne.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-06" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Tannlegeskrekk</h2>
              <span class="arrowSection" id="arrow-SI-06">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
              Du er ikke alene! Omkring 10% av Norges befolkning har tannlegeskrekk. Heldigvis finnes det en del råd og tips for å redusere angsten og gjøre opplevelsen i tannlegestolen bedre. Snakk med våre tannleger om hva du kan gjøre, og hva du kan be tannlegen din om. Det er viktig for deg og dine tenner at du kommer deg til tannlegen og får nødvendige undersøkelser og behandling, så la oss hjelpe deg på vei.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-07" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">
                Infeksjoner og betennelse i tannkjøtt og annet bløtvev
              </h2>
              <span class="arrowSection" id="arrow-SI-07">&#x2769;</span>
            </div>
            <hr></hr>
            <div class="hiddenText">
              <p>
              Infeksjoner kan oppstå spontant, eller som komplikasjon til tannbehandling som fjerning av visdomstann eller rotfylling. Infeksjoner kan arte seg om rødme og hevelse lokalt i tannkjøtt og annet bløtvev i munnen, ofte sammen med smerter og eventuelt feber. Det kan også danne seg abscesser (pussfylte hulrom). Vi kan bistå med å kartlegge situasjonen og gi råd om tiltak i egen regi og behov for behandling hos tannlege. I noen tilfeller er det behov for antibiotika, og da kan vi skrive resept på slik behandling.
              </p>
              <p>
              Det finnes flere betennelsestilstander som rammer tannkjøtt og annet bløtvev i munnen. Ofte er disse relatert til dårlig tannhygiene med manglende tannpuss og bruk av tanntråd, eller bruk av snus og annen tobakk. I noen tilfeller kan man oppleve at tannkjøttet har lett for å blø når man pusser tenner eller spiser, eller spontane blødninger. Vi kan gi råd om tiltak i egen regi og behov for behandling hos tannlege.
              </p>
            </div>
          </div>

          <div class="serviceItemCont" id="SI-08" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Ising i tennene </h2>
              <span class="arrowSection" id="arrow-SI-08">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Ising i tennene kan ha flere årsaker, ikke bare hull. Ta en prat med en av våre tannleger for å få en første vurdering, og veiledning og enkle råd til hva du kan gjøre for å lindre plagene.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-09" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Second opinion </h2>
              <span class="arrowSection" id="arrow-SI-09">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Har du vært til undersøkelse hos tannlegen og fått beskjed om at du har tannskader som krever omfattende og dyr behandling? Vi tar gjerne en prat med deg for å gi en såkalt «second opinion» på vurderingen som er gjort, og om vi synes det virker forholdsmessig og rimelig. Få gjerne utskrift av journal fra tannlegen så det blir lettere å forstå hvordan vedkommende har tenkt. Som eier av din egen journal har du krav på slik utskrift.
            </p>
          </div>

          <div class="serviceItemCont" id="SI-10" onClick={(e) => handleClick(e)}>
            <div class="flexSection">
              <h2 class="newSection">Visdomstenner</h2>
              <span class="arrowSection" id="arrow-SI-10">&#x2769;</span>
            </div>
            <hr></hr>
            <p class="hiddenText">
            Smertefulle visdomstenner er noe mange opplever. Spørsmålet som ofte melder seg er om, og eventuelt når, man burde fjerne dem. Timing av dette kan være vesentlig med tanke på ulike hendelser i livet, som eksamen, bryllup eller andre begivenheter der man ikke vil risikere smerter og/eller hevelse.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
