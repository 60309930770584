import "../../css/ArticlesPage.css"
import { useEffect, useState} from "react"
import axios from "axios";



export default function EmployeesPage() {
  const [itemsOfEmployees, setItemsOfEmployees] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_APP_URL}`+"getDentistsSlider").then((result) => {
      
      setItemsOfEmployees(result.data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleModal = (item) => {
    setSelectedItem(item);
    setModalOpen(!modalOpen);
  };
  
  const closeModal = () => {
    setModalOpen(!modalOpen);
  }

  const Modal = ({ item }) => {
    return (
      <div class="employeeModal" onClick={toggleModal}>
        <div class="employeeModalCont">
          <div class="containerClose">
          <span class="closeModal" onClick={toggleModal}>&times;</span>
          <img src={item.img}></img>

          </div>
          <h3>{`${item.firstName} ${item.lastName}`}</h3>
          <p>{item.description}</p>
        </div>
      </div>
    );
  };


  return (
    <div class="articlesPage" style={{overflow: `${modalOpen ? "hidden": "visible"}`}}>
      <h1 class="sectionHeadder" style={{opacity: `${modalOpen ? "10%": "100%"}`}}>Våre tannleger</h1>

      <p class="articleTextSection"style={{opacity: `${modalOpen ? "10%": "100%"}`}}>
        Team Abra består av noen av Norges mest erfarne tannleger, 
        med tung faglig bakgrunn og et genuint ønske om at du skal ha en enestående opplevelse med våre tjenester. 
        I all kontakt med oss kan du stole på at du får faglig bunnsolide råd og veiledning.
      </p>

      <div class="articlesCont" style={{overflow: `${modalOpen ? "hidden": "visible"}`}}>

        {itemsOfEmployees.map(item => {
          return (
            <div class="dentistCard" style={{opacity: `${modalOpen ? "10%": "100%"}`}}>
              <img class="dentistCardImg" src={item.img} onClick={() => toggleModal(item)}></img>
              <h3>{`${item.firstName} ${item.lastName}`}</h3>
              {/* <p class="dentistCardText"> {item.description}</p> */}
              <p class="dentistCardText">{item.description && `${item.description.slice(0,180)}...`}</p>
              <p class="lesMerBtn" onClick={() => toggleModal(item)}>Les mer</p>
            </div>
          );
        })}

        {modalOpen && <Modal item={selectedItem} />}
      </div>
    </div>
  )
};
