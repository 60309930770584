import "../css/LandingPage.css";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import HeroSection from "../img/heroSection2.jpg";

const images = [HeroSection, HeroSection, HeroSection];

const slideContent = [
  {
    h: "Tannskader",
    p: `Få råd fra våre tannleger om hva du bør gjøre om du har knekt eller på annen måte skadet en tann.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/reparereTann1200x600.png",
  },

  {
    h: "Barn",
    p: `Vi kan gi deg råd og veiledning i akutte situasjoner, eller om du har generelle spørsmål om dine barns tenner.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/childParentDentist1200x600.png",
  },

  {
    h: `Tannlegeskrekk`,
    p: `Det finnes hjelp å få. Snakk med oss om hva du og tannlegen din kan gjøre for å få en bedre opplevelse.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/tannlegeskrekk1200x600.png",
  },

  {
    h: `Tannpine`,
    p: `Snakk med oss om dine plager og bekymringer, så kan vi guide deg på hva som er lurt å gjøre.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/tannpine1200x600.png",
  },

  {
    h: `Tannregulering`,
    p: `La oss gi deg en helt uavhengig vurdering av hva som kan være beste løsning for dege, blant et hav ulike varianter og priser.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/tannregulering1200x600.png",
  },

  {
    h: `Tannbleking`,
    p: `Snakk med oss enten du vurderer tannbleking eller allerede har gjort det. Vi gir deg råd så du kan ta godt vare på tennene dine.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/tannbleking1200x600.png",
  },

  {
    h: `Visdomstenner`,
    p: `Visdomstenner kan være plagsomme. Snakk med våre tannleger om når du bør fjerne dem, og hva du kan forvente.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/tannsjekk1200x600.png",
  },

  {
    h: `På reise`,
    p: `Våre tannleger kan hjelpe deg når du er på hytta, på stranden, på safari eller hvor enn du måtte befinne deg.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/secondOpinion1200x600.png",
  },

  {
    h: `Second opinion`,
    p: `Om du er usikker å informasjon eller behandling du har fått hos tannlegen din, kan du få en uavhengig vurdering av våre erfarne tannleger.`,
    img: "https://bilderabradental.blob.core.windows.net/heroimages/secondOpinion1200x600.png",
  },
];

const HeroSlideshow = ({ interval = 3000 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlideIndex((prevIndex) =>
        prevIndex === slideContent.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [images, interval]);

  return (
    <Link to="/tjenester">
      <div class="heroSlideCont">
        <img
          class="heroImg"
          src={slideContent[currentSlideIndex].img}
          alt={`Slide ${currentSlideIndex}`}
        />
        <div class="heroTextCont">
          <h1>{slideContent[currentSlideIndex].h}</h1>
          <p>{slideContent[currentSlideIndex].p}</p>
          <button class="heroButton">
            <Link class="btnBook" to="/booking" id="BookTimeLink">
              Book første ledige
            </Link>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default HeroSlideshow;
