import React from "react";
import "../../css/FormStyle.css"
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import bcrypt from "bcryptjs";

//hasher passwordet før det sendes til backend og database

export default function CreateUser() {
  const navigate = useNavigate();


  var salt = bcrypt.genSaltSync(10);

  const schema = yup.object().shape({
    email: yup.string().required("Vennligst skriv inn emailen din"),
    fname: yup.string().required("Vennligst skriv inn fornavnet ditt"),
    ename: yup.string().required("Vennligst skriv inn etternavnet ditt"),
    telephone: yup.string().required("Vennligst skriv inn telefonnummeret ditt"),
    hpr: yup.string().required("Vennligst skriv inn hpr nummeret ditt"),

    //hasher passwordet
    password: yup
      .string()
      .transform(function (value) {
        return bcrypt.hashSync(value, salt);
      })
      .required("Vennligst lag et passord"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //todo sette dette opp i backenden for å lage en bruker
  async function onSubmit(data) {
    
    axios
      .post(`${process.env.REACT_APP_APP_URL}`+"createUser", {

      //todo send med flere parametere
        fname: data.fname,
        ename: data.ename,
        email: data.email,
        telephone: data.telephone,
        hpr: data.hpr,
        password: data.password,
      })
      .then((response) => {
        

        //sender respons fra backend som sier at det er vellykket, da kommer man inn til dashboardet
        //todo må sette cookie
        if (response.data === "updated") {
          document.getElementById("createUser").innerHTML = "Opprettelse av bruker vellykket"
          setInterval(() => {
            navigate("/logginn");

          }, 1500)
        }
      });
  }

  return (
    <div class="createUserPage">
      <div class="createUserPageCont">
        <h1 class="sectionHeadder">Opprett tannlege</h1>
        
        <form id="login_form" onSubmit={handleSubmit(onSubmit)}>
          <label class="formLabel sr-only">Epost</label>
          <input class={`formInput ${errors.email && "form_error"}`} {...register("email")} type="text" placeholder={errors.telephone ? errors.email.message : "Epost"}/>
          {/* {errors.email && <span className='form_error'>{errors.email.message}</span>} */}

          <label class="formLabel sr-only">Fornavn</label>
          <input class={`formInput ${errors.fname && "form_error"}`} {...register("fname")} type="text" placeholder={errors.telephone ? errors.fname.message : "Fornavn"}/>
          {/* {errors.fname && <span className='form_error'>{errors.fname.message}</span>} */}

          <label class="formLabel sr-only">Etternavn</label>
          <input class={`formInput ${errors.ename && "form_error"}`} {...register("ename")} type="text" placeholder={errors.telephone ? errors.ename.message : "Etternavn"}/>
          {/* {errors.ename && <span className='form_error'>{errors.ename.messag
            e}</span>} */}

          <label class="formLabel sr-only">Telefon</label>
          <input class={`formInput ${errors.telephone && "form_error"}`} {...register("telephone")} type="text" placeholder={errors.telephone ? errors.telephone.message : "Telefon"}/>
          {/* {errors.telephone && <span className='form_error'>{errors.telephone.message}</span>} */}

          <label class="formLabel sr-only">HPR</label>
          <input class={`formInput ${errors.hpr && "form_error"}`} {...register("hpr")} type="text" placeholder={errors.hpr ? errors.hpr.message : "HPR"}/>
          {/* {errors.hpr && <span className='form_error'>{errors.hpr.message}</span>} */}

          <label class="formLabel sr-only" htmlFor="password">Passord</label>
          <input class={`formInput form-spacer ${errors.hpr && "form_error"}`} {...register("password")} type="password" placeholder="Passord"/>
          {/* {errors.password && <span className='form_error'>{errors.password.message}</span>} */}

          <div class="loginActions">
            <p><Link to="/logginn">Allerede bruker?</Link></p>
            <input class="formBtn" type="submit" value="Opprett tannlege"></input>
          </div>
        </form>
        <div id="createUser"></div>
      </div>
    </div>
  );
}
