import axios from "axios";

export async function getHours() {
  try {
    const response = await axios.post(`${process.env.REACT_APP_APP_URL}getHours`);

    let hours = response.data;
    return hours;
  } catch (error) {
    console.error(error);
    throw error; 
  }
};
