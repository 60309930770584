import "../css/ArticlesReel.css";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Brush from "../img/articleImgBrush.png";
import Mask from "../img/articleImgMask.png";
import Rock from "../img/articleImgRock.png";
import Tools from "../img/articleImgTools.png";
import Wash from "../img/articleImgWash.png";

export default function ArticlesReel() {
  const [bloggList, setBloggList] = useState([]);
  let blogs = letsGetTheBlogs();
  const [displayedData, setDisplayedData] = useState([]);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const bloggImages = [Rock, Mask, Brush, Tools, Wash];

  function letsGetTheBlogs() {
    
    if (bloggList <= 0 || bloggList === "undefined") {
      axios
        .post(`${process.env.REACT_APP_APP_URL}` + "getAllTheBlogs")
        .then((response) => {
          
          setBloggList(response.data);
        });
    }
  }

  useEffect(() => {
    // let screenSize = window.innerWidth;
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    let numToDisplay = screenSize > 900 && bloggList.length > 3 ? 4 : 3;
    let startIndex = bloggList.length - numToDisplay;
    let endIndex = bloggList.length;

    //trenger de 4 nyeste

    if(screenSize > 900 || screenSize < 500) {
      setDisplayedData(bloggList.slice(0, 4));
    } else {
      setDisplayedData(bloggList.slice(0, 3));
    }
    
  }, [bloggList, window.innerWidth]);

  return (
    <div class="articlesReelCont">
      {displayedData.map((item, index) => {
        let azureImg = false;
        
        if (item.heroImage !== null) {
          azureImg = true;
        }
        return (
          <Link to={"/artikler/" + item.idblogg}>
            <div class="articlesReelCard">
              <div class="reelImgCont" href={``}>
                {azureImg ? (
                  <img src={item.heroImage} alt=""></img>
                ) : (
                  <img src={Brush} alt=""></img>
                )}
              </div>
              <h3 class="cardTitle" key={item.id}>
                {item.title}
              </h3>
              <p class="cardDesc">{item.content.slice(3,90)} ...</p>
              <p class="cardLesMer">Les mer</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
