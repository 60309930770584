import "../../css/HelpSection.css";
import { useEffect } from "react";

export default function Privacy() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div class="helpSectionPage">
      <h1>Personvernerklæring</h1>

      <p>
        Denne personvernerklæringen forklarer hvordan Abra Dental samler inn,
        bruker, behandler og beskytter personlig identifiserbar informasjon
        (personopplysninger) som du gir oss når du besøker og bruker nettsiden
        vår.
      </p>

      <h2>Innsamling av personopplysninger</h2>

      <p>
        Vi kan samle inn ulike typer personopplysninger når du besøker eller
        bruker nettsiden vår. Dette kan inkludere informasjon som navn,
        e-postadresse, telefonnummer og annen informasjon du frivillig oppgir
        gjennom kontaktskjemaer eller registreringsskjemaer.
      </p>

      <h2>Bruk av personopplysninger</h2>

      <p>Vi bruker de innsamlede personopplysningene til følgende formål:</p>

      <ul>
        <li>For å svare på dine henvendelser, spørsmål eller forespørsler.</li>
        <li>
          For å levere tjenester du ber om, for eksempel avtaler om
          tannbehandling eller timebestillinger.
        </li>
        <li>For å forbedre og tilpasse din opplevelse på nettsiden vår.</li>
        <li>
          For å sende deg relevant informasjon, markedsføringsmateriale eller
          nyhetsbrev, hvis du har samtykket til dette.
        </li>
        <li>
          For å administrere og opprettholde sikkerheten til nettsiden vår.
        </li>
      </ul>

      <h2>Lagring av personopplysninger</h2>

      <p>
        Vi lagrer og beskytter personopplysningene dine i tråd med gjeldende
        personvernlover og bransjestandarder. Vi tar rimelige forholdsregler for
        å sikre at dine personopplysninger ikke blir misbrukt, endret eller
        uautorisert tilgang til.
      </p>

      <h2>Deling av personopplysninger</h2>

      <p>
        Vi deler ikke dine personopplysninger med tredjeparter uten ditt
        samtykke, med mindre det er nødvendig for å oppfylle våre forpliktelser
        overfor deg eller i samsvar med gjeldende lover og forskrifter.
      </p>

      <h2>Informasjonskapsler (Cookies)</h2>

      <p>
        Vi kan bruke informasjonskapsler (cookies) på nettsiden vår for å
        forbedre brukeropplevelsen og samle inn aggregert statistikk om
        besøkende. Du kan til enhver tid endre innstillingene for
        informasjonskapsler i nettleseren din.
      </p>

      <h2>Dine rettigheter</h2>

      <p>
        Du har rett til å be om innsyn, korrigering eller sletting av
        personopplysninger vi har lagret om deg. Du kan også trekke tilbake
        eventuelle samtykker du har gitt angående bruk av dine
        personopplysninger.
      </p>

      <h2>Endringer i personvernerklæringen</h2>

      <p>
        Vi forbeholder oss retten til å oppdatere eller endre denne
        personvernerklæringen. Eventuelle endringer vil bli publisert på
        nettsiden vår, og det oppfordres til å jevnlig gjennomgå denne
        erklæringen for å holde deg informert.
      </p>

      <h2>Kontakt oss</h2>

      <p>
        Hvis du har spørsmål, bekymringer eller forespørsler angående personvern
        eller behandlingen av dine personopplysninger, kan du kontakte oss på
        følgende kontaktinformasjon:
      </p>

      <p>Abra Dental</p>
      {/* <p>Adresse: Sandakerveien 56 B, 0477 Oslo</p> */}
      <p>E-post: <a href="mailto: hei@abradental.no">hei@abradental.no</a></p>
      {/* <p>Telefon: [Ditt telefonnummer]</p> */}
    </div>
  );
}
