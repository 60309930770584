// import { Link, useNavigate } from "react-router-dom";
import "../../css/EmployeePage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import checkUser from "../../components/checkUser.js";
import { Link } from "react-router-dom";

export default function EmployeePage() {
  const [itemsOfEmployees, setItemsOfEmployees] = useState([]);
  const [accepted, setAccepted] = useState("Nei");
  const [loaded, setLoaded] = useState(false);
  const [description, setDescription] = useState("");
  const [profileImage, setProfileImage] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    checkUser("ansatte");
    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "getDentists")
      .then((result) => {
        setItemsOfEmployees(result.data);
      });
  }, []);

  // async function ChangeAcceptance(accepted, iduser) {
  //   document.getElementById(`changeButton${iduser}`).style.display = "none";
  //   document.getElementById(`extraFields${iduser}`).style.display = "grid";

  //   if (accepted === 1) {
  //     document.querySelector(`.acceptOrNot${iduser}`).innerHTML = "Slett";
  //   }

  //   if (accepted === 0) {
  //     document.querySelector(`.acceptOrNot${iduser}`).innerHTML = "Aksepter";
  //   }
  // }

  async function ChangeAcceptance(accepted, iduser) {
    document.getElementById(`changeButton${iduser}`).style.display = "none";
    document.getElementById(`extraFields${iduser}`).style.display = "grid";

    if (accepted === 1) {
      document.querySelector(`.acceptOrNot${iduser}`).innerHTML = "Slett";
    }

    if (accepted === 0) {
      document.querySelector(`.acceptOrNot${iduser}`).innerHTML = "Aksepter";
    }
    const currentUser = itemsOfEmployees.find((item) => item.iduser === iduser);
    setDescription(currentUser.description);
  }




  async function ChangeInfo(e, idUser) {
    e.preventDefault();

    let description = document.getElementById(`description${idUser}`).value;
    let profileImage = document.getElementById(`image${idUser}`);
    let profileImage2 = profileImage.files[0];

    const formData = new FormData();
    formData.append("image", profileImage2);
    formData.append("description", description);
    formData.append("id", idUser);

    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "updateUser", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.affectedRows === 1) {
          document.getElementById("feedbackChange").innerHTML =
            "Vellykket endring";

            setInterval(() => {
              window.location.reload();
            }, 1500)
        }
      });
  }

  async function AcceptUser(e, idUser) {
    let target = e.target.innerHTML;

    axios
      .post(`${process.env.REACT_APP_APP_URL}` + "updateUser", {
        target,
        id: idUser,
      })
      .then((response) => {
        if (
          response.data.affectedRows === 1 &&
          response.data.serverStatus === 2
        ) {
          document.getElementById(`changeButton${idUser}`).style.display =
            "inline";
          document.getElementsByClassName(
            `acceptOrNot${idUser}`
          )[0].style.display = "none";
          document.getElementById(`message${idUser}`).innerHTML = "Vellykket!";
          document.getElementById(`message${idUser}`).style.color = "green";
          
          window.location.reload();        }
      });
  }

  return (
    <div class="employeePage">
      <div class="employeeTrailCont">
      <Link to="/dashboardAdmin">Gå til admin-dashboard</Link>
      <Link to="/dashboard">Gå til tannlegesiden</Link>
      </div>

      <h1 class="sectionHeadder">Oversikt over tannleger</h1>

      <div class="employeeCardTags">
        <p>Navn</p>
        <p>Telefon</p>
        <p>HPR</p>
        <p>Epost</p>
        <p>Status</p>
      </div>
      <ul>
        {itemsOfEmployees.map((item) => {
          if (item.accepted === 1 && !loaded) {
            setAccepted("Ja");
            setLoaded(true);
          }

          if (item.accepted === 0 && !loaded) {
            setAccepted("Nei");
            setLoaded(true);
          }

          // returns null when onloaded. Need to find a way to make this load after the div is loaded

          return (
            <li class="employeeCardCont" id={item.iduser}>
              <div class="empCardInnerCont top">
                <p>
                  {item.firstName} {item.lastName}
                </p>
                <p>{item.telephone}</p>
                <p>{item.HPR}</p>
                <p>{item.email}</p>
                <p>{item.accepted === 1 ? "Ok" : "Venter"}</p>
              </div>

              <div class="empCardInnerCont bottom">
                <p>{item.description}</p>
                <div
                  class="employeeCardbtn"
                  onClick={(e) =>
                    ChangeAcceptance(item.accepted, item.iduser, e)
                  }
                >
                  <p id={"changeButton" + item.iduser}> Endre</p>
                  <div
                    onClick={(e) => AcceptUser(e, item.iduser)}
                    class={`acceptOrNot${item.iduser}`}
                  ></div>
                </div>
              </div>

              <div id={`message`}></div>
              <div id={`message${item.iduser}`}></div>

              <form class="extraFields" id={`extraFields${item.iduser}`}>
                <div class="empCardFormCont empDescription">
                  <label class="empCardLabel">
                    Legg inn beskrivelse av tannlegen
                  </label>
                  <textarea
                    class="formInput employeeDescription"
                    id={`description${item.iduser}`}
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>

                <div class="empCardFormCont empImg">
                  <label class="empCardLabel">
                    Last opp bilde av tannlegen
                  </label>
                  <input
                    class="formInput"
                    id={`image${item.iduser}`}
                    type="file"
                    accept="image/*"
                    placeholder="Hero bilde"
                  ></input>
                </div>

                <div class="empCardFormCont empEndre">
                  <button
                    onClick={(e) => ChangeInfo(e, item.iduser)}
                    class="btnReadMore"
                  >
                    Endre
                  </button>
                </div>
              </form>
              <div id="feedbackChange"></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
